import React, { useState } from "react";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer";
import Map from "../components/Map";
import Navbar from "../components/Navbar";
import ScrollToTop from "react-scroll-to-top";
import FooterNavMobile from "../components/FooterNavMobile";

const Index = () => {
  
  return (
    <div className="container">
      <Helmet title="Crime Reporting Gambia" />
      <Navbar />
      <ScrollToTop smooth style={{zIndex:'9999'}}/>
      <main className="map-container">
        <Map />
      </main>
      <FooterNavMobile />
      <Footer />
    </div>
  );
};

export default Index;
