import React, { useState } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import retinaUrl from "leaflet/dist/images/marker-icon-2x.png";
import iconUrl from "leaflet/dist/images/marker-icon.png";
import SOSIcon from '../assets/icons/sosMapIcon.png';
import shadowUrl from "leaflet/dist/images/marker-shadow.png";
import { Link } from "react-router-dom";

let locationIcon = null;
let sosIcon = null;

export default () => {
  const [coordinates, setCoordinates] = useState([]);
  const [sosCoordinates, setSOSCoordinates] = useState([]);

  const getCoordinates = async () => {
    let url =
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_DEVELOPMENT
        : process.env.REACT_APP_PRODUCTION;
    let response = await fetch(url + "/v1/coordinates");
    let data = await response.json();

    if (data.success) {
      setCoordinates(data.data);
    }
  };

  const getSOSCoordinates = async () => {
    let url =
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_DEVELOPMENT
        : process.env.REACT_APP_PRODUCTION;
    let response = await fetch(url + "/v1/sos-coordinates");
    let data = await response.json();

    if (data.success) {
      setSOSCoordinates(data.data);
    }
  };

  React.useEffect(() => {
    const L = require("leaflet");

    locationIcon = L.icon({ iconUrl: iconUrl });
    sosIcon = L.icon({iconUrl: SOSIcon})

    // delete L.Icon.Default.prototype._getIconUrl;

    // L.Icon.Default.mergeOptions({
    //   iconRetinaUrl: retinaUrl,
    //   iconUrl: iconUrl,
    //   shadowUrl: shadowUrl,
    // });
    getCoordinates();
    getSOSCoordinates();
  }, []);

  return (
    <MapContainer
      center={[13.4667, -16.5667]}
      zoom={12}
      style={{ height: "100vh" }}
      scrollWheelZoom={false}
      tap={false}
      scrollZoom={false}
    >
      <TileLayer
        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        key={14}
      />
      {coordinates.map((value) => {
        return (
          <>
            <Marker position={[value.lat, value.long]} icon={locationIcon} key={value.long}>
              <Popup key={value.long}>
                <Link to={`/crimes?lat=${value.lat}&long=${value.long}`} key={value.long}>
                  View Crimes
                </Link>
              </Popup>
            </Marker>
          </>
        );
      })}
      
      {sosCoordinates.map((value) => {
        return (
          <>
            <Marker position={[value.lat, value.long]} icon={sosIcon} key={value.lat}>
              <Popup key={value.long}>
                <Link to={`/sos?lat=${value.lat}&long=${value.long}`} key={value.long}>
                  View Crimes
                </Link>
              </Popup>
            </Marker>
          </>
        );
      })}
    </MapContainer>
  );
};
