import React from "react";
import { Icon } from "semantic-ui-react";

const Footer = () => {
  return (
    <div>
      <footer className="footer">
        <a
          href="https://cayorenterprises.com"
          target="_blank"
          style={{ color: "#000" }}
        >
          &copy; Cayor Enterprises{" "}
        </a>
      </footer>
    </div>
  );
};

export default Footer;
