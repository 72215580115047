import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Navbar from "../components/Navbar";
import { useParams } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import { withRouter } from "react-router";
// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
import CrimeViewNav from "../components/CrimeViewNav";
import CrimesNav from "../components/CrimesNav";
import FooterNavMobile from "../components/FooterNavMobile";


// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

const options = { year: "numeric", month: "long", day: "numeric" };

const ViewCrime = (props) => {
  const { uuid } = useParams();
  const [crime, setCrime] = useState();
  const [offence, setOffence] = useState();
  const [offenceType, setOffenceType] = useState();
  const [images, setImages] = useState([
    {url: '',
     type: ''}
  ]);

  const getCrime = async () => {
    let url =
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_DEVELOPMENT
        : process.env.REACT_APP_PRODUCTION;
    let response = await fetch(url + "/v1/crime?uuid=" + uuid);
    let data = await response.json();
    if (await data.success) {
      setCrime(data.data["0"]);
      setOffence(data.data.offence[0]);
      setOffenceType(data.data.offence_type[0]);
      let medias = [];
      data.data.images.map((value) => {
        medias.push({
          url: process.env.NODE_ENV === "development"?
          process.env.REACT_APP_UPLOADS_DEVELOPMENT + '/' + value.image_name : 
          process.env.REACT_APP_UPLOADS_PRODUCTION + '/' + value.image_name,
          type: value.type
        });
      });
      setImages([...medias]);
    }

    if(await !data.success) {
      props.history.push('/crimes');
      return;
    }

  };

  useEffect(() => {
    getCrime();
    window.scrollTo(0,0);
  }, []);

  return (
    <div className="container">
      <Helmet title={offence? offence.offence_name: '...'} />
      <CrimeViewNav crime={offence? offence.offence_name: '...'}/>
      <div className="crime-content">
        <div className="crime-content__media">
        <Swiper
          spaceBetween={0}
          slidesPerView={1}
          navigation
          pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}
          onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={() => console.log('slide change')}
        >
         {images.map((value, index) => {
           return (
             <>
              <SwiperSlide key={index}>
                  {value.type.includes('image')? (
                    <>
                    <img src={value.url} style={{maxHeight: '100%', maxWidth: '100%'}} />
                    </>
                  ) : (
                    <>
                    <video src={value.url} style={{maxWidth: '100%', maxHeight: '100%'}} controls />
                    </>
                  )}
                </SwiperSlide>
             </>
           )
         })}
        </Swiper>
        </div>
        <div className="crime-desc">
           <h2>{offence? offence.offence_name : '...'}</h2>
           <h5><Icon name="calendar alternate outline" 
           style={{color: 'red', paddingRight: '8px'}} />
           {crime? new Date(crime.created_at).toLocaleTimeString(undefined, options) : null}
           </h5>
           <h5>
             <span style={{padding: '8px', backgroundColor: '#ddd', 
             borderRadius: '25px', marginRight: '8px'}}>{crime? crime.lat : '...'}</span>
             <span style={{padding: '8px', backgroundColor: '#ddd', 
             borderRadius: '25px'}}>{crime? crime.long : '...'}</span>
           </h5>
           <h3 style={{fontWeight: 'bold'}}>Description</h3>
           <span>
            {crime ? crime.description : 'Loading...'}
           </span>
        </div>
      </div>
      <FooterNavMobile />
    </div>
  );
};

export default withRouter(ViewCrime);
