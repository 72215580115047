import React, { useCallback, useState } from "react";
import SOSIcon from '../assets/icons/sos.svg';
import { useDropzone } from "react-dropzone";
import { Icon, Button } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import SOS from '../assets/icons/sos.png'
import { Modal, TextArea } from "semantic-ui-react";
// import Notifications, { notify } from "react-notify-toast";
import {  useToasts } from 'react-toast-notifications';
import ReportIcon from '../assets/icons/report.svg';
import Camera from '../assets/icons/camera.svg';
import Image from '../assets/icons/image.svg';
import VideoCamera from '../assets/icons/video-camera.svg';
import Plus from '../assets/icons/plus.svg';
import { geolocated } from "react-geolocated";
import axios from 'axios';


const uploadedFiles = [];

const FooterNavMobile = (props) => {
  const { addToast } = useToasts();
  const [results, setResults] = useState();
  const [value, setValue] = useState();
  const [files, setFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [uploads, setUploads] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [sos, setSOS] = useState({ phone: "", description: "", long: "", lat: "", files: [] });
  const [loadingSOS, setLoadingSOS] = useState(false);
  const handleChange = (event) => {
    setSOS({ ...sos, [event.target.id]: event.target.value });
  };

  const deleteImg = (value) => {
    let updatedFiles = files.filter((file) => {
      return file !== value;
    });
    let updatedUploads = uploads.filter((file) => {
      return file.originalName !== value.name;
    });
    setUploads(updatedUploads);
    setFiles(updatedFiles);
  };

  const onDrop = useCallback((acceptedFiles) => {
    let fileBinaries = [];
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      fileBinaries.push({
        file: URL.createObjectURL(file),
        type: file.type,
        name: file.name,
      });
      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = async () => {
        await uploadFiles(file);
      };
      reader.readAsArrayBuffer(file);
    });
    setFiles(fileBinaries);
    setUploads(uploadedFiles);
  }, []);


  const { acceptedFiles, fileRejections, getRootProps, getInputProps, open } =
  useDropzone({
    accept: "image/*,video/*",
    onDrop,
    maxFiles: 5,
    noClick: true,
    noKeyboard: true
  });

const uploadFiles = async (file) => {
  setUploading(true);
  const data = new FormData();
  data.append("file", file);

  let url =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_DEVELOPMENT
      : process.env.REACT_APP_PRODUCTION;
  await axios
    .post(url + "/v1/upload-file", data, {
      headers: {
        accept: "application/json",
        "Accept-Language": "en-US,en;q=0.8",
        "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
      },
    })
    .then(async (response) => {
      if (await response.data.success) {
        uploadedFiles.push({
          name: response.data.data,
          file: process.env.REACT_APP_UPLOADS + "/" + response.data.data,
          type: file.type,
          originalName: file.name,
        });
      }
    })
    .catch((err) => {});
  setUploading(false);
};

  const sendSOS = async () => {
    setLoadingSOS(true);
    let lat = props.coords ? props.coords.latitude : 0;
    let long = props.coords ? props.coords.longitude : 0;

    if (sos.phone === "" || sos.phone.description === "") {
      addToast("All Fields Required*",{appearance: 'error', autoDismiss:true});
      setOpenModal(false);
      setLoadingSOS(false);
      return;
    }

    sos.lat = lat;
    sos.long = long;
    sos.files = uploadedFiles;

    if(files.length <= 0) {
      addToast("Error! Attach atleast one image/video", {appearance: 'error', autoDismiss: true});
      setOpenModal(false);
      setLoadingSOS(false);
      return;
    }

    let url =
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_DEVELOPMENT
        : process.env.REACT_APP_PRODUCTION;
    let response = await fetch(url + "/v1/sos", {
      method: "POST",
      body: JSON.stringify(sos),
      headers: { "Content-Type": "application/json" },
    });
    let data = await response.json();

    if (await data.success) {
      setOpenModal(false);
      setSOS({ phone: "", description: "" });
      setLoadingSOS(false);
      addToast("SOS Notification Sent!", {appearance: 'success', autoDismiss:true});
    } else {
      setOpenModal(false);
      setSOS({ phone: "", description: "" });
      addToast("Server Error!", {appearance: 'error', autoDismiss: true});
      setOpenModal(false);
      setLoadingSOS(false);
    }
    setLoadingSOS(false);
  };
    return (
        <div>
         <div className="bottom-nav">
          <div className="map" onClick={() => props.history.push('/')}>
            <Icon name="map" style={{color: '#FF4500', maxWidth: '100%', maxHeight: '100%'}}/>
            <span>Map</span>
          </div>
          <div className="report" onClick={(e) => props.history.push('/report')}>
            <img src={ReportIcon} style={{maxHeight: '100%', maxWidth: '100%'}} />
            <span>Report Crime</span>
          </div>
          <div className="sos-btn" onClick={() => setOpenModal(true)}>
            <img src={SOSIcon} style={{maxHeight: '100%', maxWidth: '100%'}} />
          <span>SOS</span>
          </div>
        </div>
        <Modal dimmer="inverted" open={openModal} onClose={() => setOpenModal(false)}>
        <Modal.Header>Send SOS Message?</Modal.Header>
        <Modal.Content>
          <form>
             <div className="form-group">
              <label>Phone Number</label>
                <input
                  placeholder=""
                  id="phone"
                  onChange={handleChange}
                  required
                  type="text"
                />
             </div>
             <div className="form-group">
             <label>Crime Description</label>
              <TextArea
                placeholder=""
                id="description"
                onChange={handleChange}
                required
              />
             </div>
             <div {...getRootProps()}>
              <div className="form-group">
                <label>media</label>
                <input {...getInputProps()} />
                <div className="medias" >
                  <div className="camera" onClick={open}>
                    <img src={Camera} style={{maxHeight: '30%', maxWidth: '30%'}} />
                  </div>
                  
                </div>
              </div>
              <div className="form-group">
                <label>Files  {uploading ? (
                      'Uploading...'
                    ) : null}</label>
                <div className="files">
                 <div className="file" style={{height: '90px', width: '120px'}}>
                 {files.map((value) => {
                      return (
                        <>
                          {value.type.includes("image") ? (
                            <>
                              <img
                                src={value.file}
                                style={{
                                  maxWidth: "100%",
                                  maxHeight: "100%",
                                  margin: "5px",
                                }}
                              />
                              <Icon
                                name="trash"
                                onClick={() => deleteImg(value)}
                                style={{ cursor: "pointer" }}
                              />
                            </>
                          ) : (
                            <>
                              <video
                                src={value.file}
                                style={{
                                  maxWidth: "100%",
                                  maxHeight: "100%",
                                  margin: "5px",
                                }}
                                controls
                              />
                              <Icon
                                name="trash"
                                onClick={() => deleteImg(value)}
                                style={{ cursor: "pointer" }}
                              />
                            </>
                          )}
                        </>
                      );
                    })}
                 </div>
                </div>
              </div>
              </div>
            </form>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => setOpenModal(false)}>
            Close
          </Button>
          <Button
            positive
            onClick={sendSOS}
            loading={loadingSOS}
            style={{ width: "80px" }}
          >
            Send
          </Button>
        </Modal.Actions>
      </Modal>
        </div>
    )
}

export default withRouter(geolocated({
  positionOptions: {
    enableHighAccuracy: true,
    maximumAge: 0,
    timeout: Infinity,
  },
  watchPosition: false,
  userDecisionTimeout: null,
  suppressLocationOnMount: false,
  geolocationProvider: navigator.geolocation,
  isOptimisticGeolocationEnabled: true,
})((FooterNavMobile)));
