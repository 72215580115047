import React from 'react';

const Crime = ({media, offence, offenceType, type}) => {
    return (
        <div className="crime-details">
            <div className="detail">
                <div className="img">
                  {type.includes('image')? (
                      <>
                        <img src={media} style={{maxWidth: '100%', maxHeight: '100%'}} />
                      </>
                  ) : null}
                  {type.includes('video')? (
                      <>
                         <video src={media} controls autoPlay={false} style={{maxWidth: '100%', maxHeight: '100%'}} />
                      </>
                  ) : null}
                </div>
                <div className="desc">
                    <h3>{offence}</h3>
                    <h5>{offenceType}</h5>
                </div>
            </div>
        </div>
    )
}

export default Crime
