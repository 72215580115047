import React from "react";
import { Switch, Route } from "react-router-dom";
import CreateOffense from "./views/CreateOffense";
import Crime from "./components/Crime";
import Crimes from "./views/Crimes";
import Index from "./views/Index";
import Report from "./views/Report";
import ViewCrime from "./views/ViewCrime";
import SOS from "./views/SOS";

const App = () => {
  return (
    <div>
      <Switch>
        <Route exact path="/" component={Index} />
        <Route exact path="/crimes" component={Crimes} />
        <Route exact path="/report" component={Report} />
        <Route exact path="/crime" component={Crime} />
        <Route exact path="/sos" component={SOS} />
        <Route exact path="/create-offense-code" component={CreateOffense} />
        <Route exact path="/crimes/:uuid" component={ViewCrime} />
      </Switch>
    </div>
  );
};

export default App;
