import React, { useCallback, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { Icon, Search, Popup, Button } from "semantic-ui-react";
import { Link, withRouter } from "react-router-dom";
import SOS from '../assets/icons/sos.png'
import { Modal, Form, TextArea } from "semantic-ui-react";
// import Notifications, { notify } from "react-notify-toast";
import {  useToasts } from 'react-toast-notifications';
import NotificationIcon from '../assets/icons/notification.svg';
import ReportIcon from '../assets/icons/report.svg';
import Camera from '../assets/icons/camera.svg';
import Image from '../assets/icons/image.svg';
import VideoCamera from '../assets/icons/video-camera.svg';
import Plus from '../assets/icons/plus.svg';
import { geolocated } from "react-geolocated";
import axios from 'axios';

const Navbar = (props) => {

  const report = (e) => {
    e.preventDefault();
    props.history.push("/report");
  };
  return (
    <div>
      <header className="nav-header">
        <nav className="nav-header__nav">
          <div className="sos-box">
              <div className="sos">
                 <img src={SOS} onClick={() => props.history.push('/sos')} style={{maxHeight: '100%', maxWidth: '100%'}} className="img" />
            </div>
          </div>
          <div className="home">
            <h2>Home</h2>
          </div>
          <div className="icons">
            <ul>
            <li className="hide">
                <Link to="/report" style={{ color: "#000" }}>
                    <img src={ReportIcon} style={{maxHeight:'120%', maxWidth: '120%'}} />
                </Link>
              </li>
              <li className="hide">
                <Link to="/" style={{ color: "#000" }}>
                   <Icon name="map" style={{fontSize: '24px'}}/>
                </Link>
              </li>
              <li className="show">
                <Link to="/crimes" style={{ color: "#000" }}>
                   <img src={NotificationIcon} style={{maxHeight:'120%', maxWidth: '120%'}} />
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    
    </div>
  );
};

export default withRouter(Navbar);
