import React from "react";
import { Icon } from "semantic-ui-react";
import { Link, withRouter } from "react-router-dom";
import Notifications from "react-notify-toast";
import Back from '../assets/icons/back.svg';
import NotificationIcon from '../assets/icons/notification.svg';
import ReportIcon from '../assets/icons/report.svg';

const CrimeViewNav = ({crime}) => {
  return (
    <div>
      <Notifications />
      <header className="nav-header">
        <nav className="nav-header__nav">
            <div className="sos-box">
              <div className="sos">
                <Link to="/crimes">
                    <img src={Back} style={{maxHeight: '120%', maxWidth: '120%'}} />
                </Link>
            </div>
          </div>
          <div className="home">
            <h2>{crime}</h2>
          </div>
          <div className="icons">
            <ul>
            
              <li className="hide">
                <Link to="/report" style={{ color: "#000" }}>
                    <img src={ReportIcon} style={{maxHeight:'120%', maxWidth: '120%', fontSize: '24px'}} />
                </Link>
              </li>
              <li className="hide">
                <Link to="/" style={{ color: "#000" }}>
                   <Icon name="map" style={{fontSize: '24px'}}/>
                </Link>
              </li>
              <li className="show">
                <Link to="/crimes" style={{ color: "#000" }}>
                   <img src={NotificationIcon} style={{maxHeight:'120%', maxWidth: '120%'}} />
                </Link>
              </li>

            </ul>
          </div>
        </nav>
      </header>
    </div>
  );
};

export default withRouter(CrimeViewNav);
