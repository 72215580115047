import React, { useState } from "react";
import { Helmet } from "react-helmet";
import Navbar from "../components/Navbar";
import { Button, Checkbox, Form, Icon, Loader } from "semantic-ui-react";
import {  useToasts } from 'react-toast-notifications';

const CreateOffense = () => {
  const { addToast } = useToasts();
  const [types, setTypes] = useState([{ type: "" }]);
  const [offenceName, setOffenceName] = useState("");
  const [loading, setLoading] = useState(false);

  const saveTypes = (e, index) => {
    e.preventDefault();
    const { id, value } = e.target;
    const temp = [...types];
    temp[index][id] = value;
    setTypes(temp);
  };

  const saveOffence = (e) => {
    setOffenceName(e.target.value);
  };

  const addRow = (e) => {
    e.preventDefault();
    types.push({
      type: "",
    });
    setTypes([...types]);
  };

  const removeRow = (e, index) => {
    e.preventDefault();
    if (types.length === 1) return 0;
    types.splice(index, 1);
    setTypes([...types]);
  };

  const createOffenceCode = async (e) => {
    e.preventDefault();
    setLoading(true);
    let url =
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_DEVELOPMENT
        : process.env.REACT_APP_PRODUCTION;

    let response = await fetch(url + "/v1/create-offense", {
      method: "POST",
      body: JSON.stringify({ offence: offenceName, offence_types: types }),
      headers: { "Content-Type": "application/json" },
    });
    let data = await response.json();

    if (data.success) {
      setOffenceName("");
      setTypes([{ type: "" }]);
      addToast(data.message, {appearance: 'success', autoDismiss: true});
      return;
    }
    addToast(
      (data.message ? data.message : null) ||
        (data.message.offense ? data.message.offense : null) ||
        (data.message.offense_types ? data.message.offense_types : null),
      {appearance: 'error', autoDismiss: true}
    );
    setLoading(false);
    return;
  };

  return (
    <div>
      <Helmet title="Create Offence Code" />
      <Navbar />
      <main>
        <div className="offence-container">
          <Form onSubmit={createOffenceCode}>
            <Form.Field>
              <label>Offence</label>
              <input
                placeholder="Offence"
                id="offence"
                required
                value={offenceName}
                onChange={saveOffence}
              />
            </Form.Field>
            {types.map((value, index) => {
              return (
                <>
                  <Form.Field>
                    <label>Offence Types</label>
                    <input
                      placeholder="Offence Type"
                      required
                      id="type"
                      value={types[index]["type"]}
                      onChange={(e) => saveTypes(e, index)}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Button onClick={(e) => addRow(e)}>
                      <Icon name="add" />
                    </Button>
       
                    <Button onClick={(e) => removeRow(e, index)}>
                      <Icon name="minus" />
                    </Button>
                  </Form.Field>
                </>
              );
            })}

            <Form.Field>
            <Button type="submit" basic loading={loading} style={{width: '100px'}}>
              Create
            </Button>
            </Form.Field>
          </Form>
        </div>
      </main>
    </div>
  );
};

export default CreateOffense;
