import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'semantic-ui-css/semantic.min.css';
import App from './App';
import { BrowserRouter as Router} from 'react-router-dom';
import { ToastProvider, DefaultToastContainer } from 'react-toast-notifications';

ReactDOM.render(
  <React.StrictMode>
    <DefaultToastContainer  css={{ zIndex: 9999 }}
    // style={{ background: "red" }}
>
    <ToastProvider>
      <Router>
        <App />
    </Router>
    </ToastProvider>
    </DefaultToastContainer>
  </React.StrictMode>,
  document.getElementById('root')
);

