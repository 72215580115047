import React, { useCallback, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { Helmet } from "react-helmet";
import ReportNav from "../components/ReportNav";
import {
  Button,
  Checkbox,
  Form,
  Dropdown,
  Icon,
  Loader,
} from "semantic-ui-react";
import formData from "form-data";
import { geolocated } from "react-geolocated";
import axios from "axios";
import Notifications, { notify } from "react-notify-toast";
import Camera from '../assets/icons/camera.svg';
import Image from '../assets/icons/image.svg';
import VideoCamera from '../assets/icons/video-camera.svg';
import Plus from '../assets/icons/plus.svg';
import {  useToasts } from 'react-toast-notifications';

const uploadedFiles = [];

const Report = (props) => {
  const { addToast } = useToasts();
  const [files, setFiles] = useState([]);
  const [offences, setOffences] = useState([]);
  const [offenceTypes, setOffenceTypes] = useState([]);
  const [offenceId, setOffenceId] = useState();
  const [offenceTypeId, setOffenceTypeId] = useState();
  const [images, setImages] = useState([]);
  const [description, setDescription] = useState();
  const [uploading, setUploading] = useState(false);
  const [uploads, setUploads] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);

  const deleteImg = (value) => {
    let updatedFiles = files.filter((file) => {
      return file !== value;
    });
    let updatedUploads = uploads.filter((file) => {
      return file.originalName !== value.name;
    });
    setUploads(updatedUploads);
    setFiles(updatedFiles);
  };

  const getOffences = async () => {
    let url =
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_DEVELOPMENT
        : process.env.REACT_APP_PRODUCTION;
    let response = await fetch(url + "/v1/offences");
    let data = await response.json();

    if (data.success) {
      setOffences([...data.data]);
    }
  };

  const getOffenceTypes = async (offenceID) => {
    let url =
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_DEVELOPMENT
        : process.env.REACT_APP_PRODUCTION;
    let response = await fetch(
      url + "/v1/offence-types?offence_id=" + offenceID
    );
    let data = await response.json();

    if (data.success) {
        setOffenceTypes([...data.data]);
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    let fileBinaries = [];
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      files.push({
        file: URL.createObjectURL(file),
        type: file.type,
        name: file.name,
      });
      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = async () => {
        await uploadFiles(file);
      };
      reader.readAsArrayBuffer(file);
    });
    // console.log(fileBinaries);
    // setFiles([...files,...fileBinaries]);
    setUploads(uploadedFiles);
  }, []);

  const handleChange = (e) => {
     if(e.target.id === "offenceID") {
        getOffenceTypes(e.target.value);
        setOffenceId(e.target.value);
        return;
     }
     setOffenceTypeId(e.target.value);
     return;
  };

  const { acceptedFiles, fileRejections, getRootProps, getInputProps, open } =
    useDropzone({
      accept: "image/*,video/*",
      onDrop,
      maxFiles: 5,
      noClick: true,
      noKeyboard: true
    });

  const uploadFiles = async (file) => {
    setUploading(true);
    const data = new FormData();
    data.append("file", file);

    let url =
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_DEVELOPMENT
        : process.env.REACT_APP_PRODUCTION;
    await axios
      .post(url + "/v1/upload-file", data, {
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
        },
      })
      .then(async (response) => {
        if (await response.data.success) {
          uploadedFiles.push({
            name: response.data.data,
            file: process.env.REACT_APP_UPLOADS + "/" + response.data.data,
            type: file.type,
            originalName: file.name,
          });
        }
      })
      .catch((err) => {});
    setUploading(false);
  };

  const reportCrime = async (e) => {
    e.preventDefault();
    setSubmitLoading(true);
    let lat = props.coords ? props.coords.latitude : 0;
    let long = props.coords ? props.coords.longitude : 0;
    let url =
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_DEVELOPMENT
        : process.env.REACT_APP_PRODUCTION;
    let response = await fetch(url + "/v1/report-crime", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        lat: lat,
        long: long,
        offence: offenceId,
        offenceType: offenceTypeId,
        description: description,
        files: uploads,
      }),
    });
    let data = await response.json();
    if (data.success) {
      setSubmitLoading(false)
      setOffenceTypeId(0);
      setOffenceId(0);
      setDescription("");
      setFiles([]);
      setUploads([]);
      addToast("Crime Report Sent", {appearance: 'success', autoDismiss: true});
      let response = await fetch(process.env.REACT_APP_ONE_SIGNAL, {
        method: 'POST',
        headers: {
          'Authorization': 'bearer ' + process.env.REACT_APP_ONE_SIGNAL_KEY,
          'Content-Type': 'application/json'
        },
        body: {
          "app_id": "0a9af3e2-b720-4dd7-834c-080d912b7141",
          "included_segments": ["Subscribed Users"],
          "data": {"crime": "data"},
          "contents": {"en": "New Crime Reported"},
        },
        mode: 'no-cors'
      });
      let result = await response.json();

      console.log(result);
    } else {
      setSubmitLoading(false)
      addToast(
        data.message
          ? data.message.lat
            ? data.message.lat
            : null || data.message.long
            ? data.message.long
            : null || data.message.description
            ? data.message.description[0]
            : null || data.message.offence
            ? data.message.offence[0]
            : null || data.message.offenceType
            ? data.message.offenceType[0]
            : null
          : null,
        {appearance: 'error', autoDismiss: true}
      );
    }
    setSubmitLoading(false);
  };

  useEffect(() => {
    getOffences();
  }, []);
  return (
    <div className="container">
      <Helmet title="Report Crime" />
      <ReportNav name="Report Crime"/>
      <main>
        <div className="report-crime">
           <form onSubmit={reportCrime}>
              <div className="form-group">
                <label>Offence</label>
                <select onChange={handleChange} id="offenceID">
                  <option value={0}> - Select Offence</option>
                  {offences.map((value) => {
                    return (
                      <>
                       <option value={value.id}>{value.offence_name}</option>
                      </>
                    )
                  })}
                </select>
              </div>
              <div className="form-group">
                <label>Offence Type</label>
                <select onChange={handleChange} id="offenceTypeID">
                  <option value={0}> - Select Offence Type</option>
                  {offenceTypes.map((value) => {
                    return (
                      <>
                       <option value={value.id}>{value.offence_type_name}</option>
                      </>
                    )
                  })}
                </select>
              </div>
              <div className="form-group">
                <label>Crime Description</label>
                <textarea onChange={(e) => setDescription(e.target.value) }></textarea>
              </div>

              <div {...getRootProps()}>
              <div className="form-group">
                <label>media</label>
                <input {...getInputProps()} />
                <div className="medias" >
                  <div className="camera" onClick={open}>
                    <img src={Camera} style={{maxHeight: '30%', maxWidth: '30%'}} />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label>Files  {uploading ? (
                      'Uploading...'
                    ) : null}</label>
                <div className="files">
                 <div className="file" style={{height: '90px', width: '120px'}}>
                 {files.map((value) => {
                      return (
                        <>
                          {value.type.includes("image") ? (
                            <>
                              <img
                                src={value.file}
                                style={{
                                  maxWidth: "100%",
                                  maxHeight: "100%",
                                  margin: "5px",
                                }}
                              />
                              <Icon
                                name="trash"
                                onClick={() => deleteImg(value)}
                                style={{ cursor: "pointer" }}
                              />
                            </>
                          ) : (
                            <>
                              <video
                                src={value.file}
                                style={{
                                  maxWidth: "100%",
                                  maxHeight: "100%",
                                  margin: "5px",
                                }}
                                controls
                              />
                              <Icon
                                name="trash"
                                onClick={() => deleteImg(value)}
                                style={{ cursor: "pointer" }}
                              />
                            </>
                          )}
                        </>
                      );
                    })}
                 </div>
                </div>
              </div>
              </div>

              <button type="submit">{submitLoading?
               'Loading...' 
              : 'Submit'}</button>
           </form>
            {/* <Form.Field>
              <label>Offence</label>
              <Dropdown
                clearable
                options={options}
                selection
                onChange={selectOffence}
                required
              />
            </Form.Field>
            <Form.Field>
              <label>Offence Type</label>
              <Dropdown
                clearable
                options={subOptions}
                selection
                disabled={offence.length <= 0}
                onChange={selectOffenceType}
                required
              />
            </Form.Field>
            <Form.Field>
              <Form.TextArea
                label="Crime Description"
                placeholder="Tell us more about the crime..."
                onChange={saveDescription}
                required
              />
              <section className="image-container">
                <div
                  {...getRootProps({ className: "dropzone" })}
                  style={{ height: "35px", cursor: "pointer" }}
                >
                  <input {...getInputProps()} />

                  <p>
                    <Icon name="plus circle" style={{fontSize: '24px'}}/>
                    <Icon name="camera" style={{fontSize: '24px'}} /> 
                     <Icon name="envira gallery"  style={{fontSize: '24px'}}/>
                     <strong>Tap/Click to Add Images/Videos (Max: 5)</strong>
                    {uploading ? (
                      <>
                        {" "}
                        Uploading <Loader active inline />
                      </>
                    ) : null}
                  </p>
                </div>
                <aside>
                  <h4>Files</h4>
                  <div style={{ height: "100px" }}>
                    {files.map((value) => {
                      return (
                        <>
                          {value.type.includes("image") ? (
                            <>
                              <img
                                src={value.file}
                                style={{
                                  maxWidth: "100%",
                                  maxHeight: "100%",
                                  margin: "5px",
                                }}
                              />
                              <Icon
                                name="trash"
                                onClick={() => deleteImg(value)}
                                style={{ cursor: "pointer" }}
                              />
                            </>
                          ) : (
                            <>
                              <video
                                src={value.file}
                                style={{
                                  maxWidth: "100%",
                                  maxHeight: "100%",
                                  margin: "5px",
                                }}
                                controls
                              />
                              <Icon
                                name="trash"
                                onClick={() => deleteImg(value)}
                                style={{ cursor: "pointer" }}
                              />
                            </>
                          )}
                        </>
                      );
                    })}
                  </div>
                </aside>
              </section>
            </Form.Field>
            <Button type="submit" basic loading={submitLoading} style={{width: '100px'}}>Submit</Button>
          </Form> */}
        </div>
      </main>
    </div>
  );
};

export default geolocated({
  positionOptions: {
    enableHighAccuracy: true,
    maximumAge: 0,
    timeout: Infinity,
  },
  watchPosition: false,
  userDecisionTimeout: null,
  suppressLocationOnMount: false,
  geolocationProvider: navigator.geolocation,
  isOptimisticGeolocationEnabled: true,
})(Report);
