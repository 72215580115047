import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import CrimesNav from "../components/CrimesNav";
import { Pagination, Select } from "semantic-ui-react";
import Crime from '../components/Crime';
import { Link } from 'react-router-dom';
import FooterNavMobile from "../components/FooterNavMobile";

const Crimes = (props) => {
  let query = new URLSearchParams(props.location.search);
  let queryLat = query.get("lat");
  let queryLong = query.get("long");
  let queryPage = query.get("page");

  const [crimes, setCrimes] = useState();
  const [page, setPage] = useState(queryPage ? queryPage : 1);
  const [lat, setLat] = useState(queryLat);
  const [long, setLong] = useState(queryLong);
  const [offences, setOffences] = useState([]);
  const [offenceID, setOffenceID] = useState();

  const getReportedCrimes = async () => {
    let url =
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_DEVELOPMENT
        : process.env.REACT_APP_PRODUCTION;
    let response = await fetch(url + "/v1/crimes?page=" + page);
    let data = await response.json();

    if (data.success) {
      setCrimes(data);
    }
  };

  const getReportedCrimesByCoord = async () => {
    let url =
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_DEVELOPMENT
        : process.env.REACT_APP_PRODUCTION;
    let response = await fetch(
      url +
        "/v1/crimes-by-coordinate?lat=" +
        lat +
        "&long=" +
        long +
        "&page=" +
        page
    );
    let data = await response.json();

    if (data.success) {
      setCrimes(data);
    }
  };

  const getAllOffences = async () => {
    let url =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_DEVELOPMENT
      : process.env.REACT_APP_PRODUCTION;
    let response = await fetch(url + '/v1/offences');
    let data = await response.json();

    if(data.success) {
        setOffences([...data.data]);
    } 
  }

  const filterByOffence = async (event) => {
    if(event.target.value == "0") {
      await getReportedCrimes();
      return;
    }

    let url =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_DEVELOPMENT
      : process.env.REACT_APP_PRODUCTION;
    let response = await fetch(url + '/v1/filter-by-offence?id=' + event.target.value);
    let data = await response.json();

    if(data.success) {
      setOffenceID(event.target.value);
      setCrimes(data);
    }
  }

  const getOffenceByID = async (offenceID) => {
    let url =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_DEVELOPMENT
      : process.env.REACT_APP_PRODUCTION;
    let response = await fetch(url + '/v1/filter-by-offence?id=' + offenceID);
    let data = await response.json();

    if(data.success) {
      setCrimes(data);
    }
  }

  const filterByTime = async (event) => {
    if(event.target.value == "0") {
      await getOffenceByID(offenceID);
      return;
    }

    let url =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_DEVELOPMENT
      : process.env.REACT_APP_PRODUCTION;
    let response = await fetch(url + '/v1/filter-by-time?time='
     + event.target.value + '&id=' + offenceID);
    let data = await response.json();

    if(data.success) {
      setCrimes(data);
    }
  }

  useEffect(() => {
    if (!queryLat || !queryLong) {
      getReportedCrimes();
    } else {
      getReportedCrimesByCoord();
    }
    getAllOffences();
  }, [page, queryLat, queryLong]);
  return (
    <div className="container">
      <Helmet title="Crimes" />
      <CrimesNav />
      <main>
        <div className="filter">
          <div className="filter-content">
            <div className="crimes-filter">
               <label>All Crimes</label>
               <select onChange={filterByOffence}>
                 <option value="0">Offences</option>
                 {offences.map((value) => {
                   return (
                     <>
                       <option value={value.id}>{value.offence_name}</option>
                     </>
                   )
                 })}
               </select>
            </div>
            <div className="time-filter">
            <label>Time</label>
               <select onChange={filterByTime}>
                 <option value="0">All Time</option>
                 <option value="24">24hrs</option>
                 <option value="12">12hrs</option>
                 <option value="6">6hrs</option>
                 <option value="1">1hr</option>
               </select>
            </div>
          </div>
        </div>
        {crimes ? (
          <>
            {crimes.data.data.length >= 1 ? (
              <>
                <div className="crimes-container">
                  {crimes.data.data.map((value) => {
                    return (
                      <>
                       <Link to={`/crimes/${value.uuid}`}>
                        <Crime                       
                          offence={value.offence_name}
                          offenceType={value.offence_type_name}
                          description={value.description}
                          type={value.type}
                          media={process.env.NODE_ENV === "development"?
                          process.env.REACT_APP_UPLOADS_DEVELOPMENT + '/' + value.image_name : 
                          process.env.REACT_APP_UPLOADS_PRODUCTION + '/' + value.image_name}
                        />
                        </Link>
                      </>
                    );
                  })}
                  {crimes.data.last_page > 1 ? (
                    <>
                      <Pagination
                        defaultActivePage={1}
                        firstItem={null}
                        lastItem={null}
                        pointing
                        onPageChange={(e, { activePage }) =>
                          setPage(activePage)
                        }
                        secondary
                        totalPages={crimes.data.last_page}
                      />
                    </>
                  ) : null}
                </div>
              </>
            ) : (
              <>
                <div className="no-result">
                  <h2>No Crimes</h2>
                </div>
              </>
            )}
          </>
        ) : (
          <>
            <div className="loading">
              <h2>Loading...</h2>
            </div>
          </>
        )}
      </main>
      <FooterNavMobile />
    </div>
  );
};

export default Crimes;
