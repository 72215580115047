import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Pagination, Select } from "semantic-ui-react";
import Crime from '../components/Crime';
import { Link } from 'react-router-dom';
import FooterNavMobile from "../components/FooterNavMobile";
import SOSNav from "../components/SOSNav";
import SOSComp from "../components/SOSComp";

const SOS = (props) => {
  let query = new URLSearchParams(props.location.search);
  let queryLat = query.get("lat");
  let queryLong = query.get("long");
  let queryPage = query.get("page");

  const [sos, setSOS] = useState();
  const [page, setPage] = useState(queryPage ? queryPage : 1);
  const [lat, setLat] = useState(queryLat);
  const [long, setLong] = useState(queryLong);

  const getReportedSOS = async () => {
    let url =
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_DEVELOPMENT
        : process.env.REACT_APP_PRODUCTION;
    let response = await fetch(url + "/v1/sos?page=" + page);
    let data = await response.json();

    if (data.success) {
      setSOS(data);
    }
  };

  const getReportedSOSByCoord = async () => {
    let url =
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_DEVELOPMENT
        : process.env.REACT_APP_PRODUCTION;
    let response = await fetch(
      url +
        "/v1/sos-by-coordinate?lat=" +
        lat +
        "&long=" +
        long +
        "&page=" +
        page
    );
    let data = await response.json();

    if (data.success) {
      setSOS(data);
    }
  };


  const filterByTime = async (event) => {
    
    let url =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_DEVELOPMENT
      : process.env.REACT_APP_PRODUCTION;
    let response = await fetch(url + '/v1/filter-by-sos-time?time='
     + event.target.value + '&id=');
    let data = await response.json();

    if(data.success) {
      setSOS(data);
    }
  }

  useEffect(() => {
    if (!queryLat || !queryLong) {
      getReportedSOS();
    } else {
      getReportedSOSByCoord();
    }
  }, [page, queryLat, queryLong]);
  return (
    <div className="container">
      <Helmet title="Crimes" />
      <SOSNav />
      <main>
        <div className="filter">
          <div className="filter-content">
            <div className="time-filter">
            <label>Time</label>
               <select onChange={filterByTime}>
                 <option value="0">All Time</option>
                 <option value="24">24hrs</option>
                 <option value="12">12hrs</option>
                 <option value="6">6hrs</option>
                 <option value="1">1hr</option>
               </select>
            </div>
          </div>
        </div>
        {sos ? (
          <>
            {sos.data.data.length >= 1 ? (
              <>
                <div className="crimes-container">
                  {sos.data.data.map((value) => {
                    return (
                      <>
                        <SOSComp                       
                          phoneNumber={value.phone_number}
                          crimeDescription={value.crime_description}
                          lat={value.lat}
                          long={value.long}
                          type={value.type}
                          media={process.env.NODE_ENV === "development"?
                          process.env.REACT_APP_UPLOADS_DEVELOPMENT + '/' + value.image_name : 
                          process.env.REACT_APP_UPLOADS_PRODUCTION + '/' + value.image_name}
                        />
                      </>
                    );
                  })}
                  {sos.data.last_page > 1 ? (
                    <>
                      <Pagination
                        defaultActivePage={1}
                        firstItem={null}
                        lastItem={null}
                        pointing
                        onPageChange={(e, { activePage }) =>
                          setPage(activePage)
                        }
                        secondary
                        totalPages={sos.data.last_page}
                      />
                    </>
                  ) : null}
                </div>
              </>
            ) : (
              <>
                <div className="no-result">
                  <h2>No Crimes</h2>
                </div>
              </>
            )}
          </>
        ) : (
          <>
            <div className="loading">
              <h2>Loading...</h2>
            </div>
          </>
        )}
      </main>
      <FooterNavMobile />
    </div>
  );
};

export default SOS;
