import React from 'react';

const SOSComp = ({media, crimeDescription, phoneNumber,long, lat, type}) => {
    return (
        <div className="crime-details">
            <div className="detail">
                <div className="img">
                  {type.includes('image')? (
                      <>
                        <img src={media} style={{maxWidth: '100%', maxHeight: '100%'}} />
                      </>
                  ) : null}
                  {type.includes('video')? (
                      <>
                         <video src={media} controls autoPlay={false} style={{maxWidth: '100%', maxHeight: '100%'}} />
                      </>
                  ) : null}
                </div>
                <div className="desc">
                    <h3>Phone : {phoneNumber}</h3>
                    <h5>Description : {crimeDescription}</h5>
                    <ul>
                        <li><span>{lat}</span></li>
                        <li><span>{long}</span></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default SOSComp;
